import React from 'react';
import Row from 'react-bootstrap/Row';
import { ProductsAndServicesContainer, ProductsAndServicesContainerTitle, ProductsAndServicesProductWrapper, ProductsAndServicesProductTitle, ProductsAndServicesCol } from './ProductsAndServices.styled';

const ProductsAndServices = () => {
  return (
    <ProductsAndServicesContainer id='Products' fluid>
      <ProductsAndServicesContainerTitle>Our Products</ProductsAndServicesContainerTitle>
        <Row>
          <ProductsAndServicesCol md={6}>
            <ProductsAndServicesProductWrapper>
              <ProductsAndServicesProductTitle>Activated Alumina</ProductsAndServicesProductTitle>
            </ProductsAndServicesProductWrapper>
          </ProductsAndServicesCol>

          <ProductsAndServicesCol md={6}>
            <ProductsAndServicesProductWrapper>
              <ProductsAndServicesProductTitle>Ammonium Bicarbonate Food Grade</ProductsAndServicesProductTitle>
            </ProductsAndServicesProductWrapper>
          </ProductsAndServicesCol>

          <ProductsAndServicesCol md={6}>
            <ProductsAndServicesProductWrapper>
              <ProductsAndServicesProductTitle>Hydroxylammonium sulfate</ProductsAndServicesProductTitle>
            </ProductsAndServicesProductWrapper>
          </ProductsAndServicesCol>

          <ProductsAndServicesCol md={6}>
            <ProductsAndServicesProductWrapper>
              <ProductsAndServicesProductTitle>MDEA</ProductsAndServicesProductTitle>
            </ProductsAndServicesProductWrapper>
          </ProductsAndServicesCol>

            <ProductsAndServicesCol md={6}>
              <ProductsAndServicesProductWrapper>
                <ProductsAndServicesProductTitle>Natural Gas treatment Chemicals</ProductsAndServicesProductTitle>
              </ProductsAndServicesProductWrapper>
            </ProductsAndServicesCol>

            <ProductsAndServicesCol md={6}>
              <ProductsAndServicesProductWrapper>
                <ProductsAndServicesProductTitle>Nickel Catalyst for Edible Oil</ProductsAndServicesProductTitle>
              </ProductsAndServicesProductWrapper>
            </ProductsAndServicesCol>

            <ProductsAndServicesCol md={6}>
              <ProductsAndServicesProductWrapper>
                <ProductsAndServicesProductTitle>Nickel Catalyst for Fatty Acid</ProductsAndServicesProductTitle>
              </ProductsAndServicesProductWrapper>
            </ProductsAndServicesCol>

            <ProductsAndServicesCol md={6}>
              <ProductsAndServicesProductWrapper>
                <ProductsAndServicesProductTitle>Sodium Methylate for Biodiesel process</ProductsAndServicesProductTitle>
              </ProductsAndServicesProductWrapper>
            </ProductsAndServicesCol>
        </Row>
    </ProductsAndServicesContainer>
  )
}

export default ProductsAndServices