import React from 'react';
import { Col } from 'react-bootstrap';
import AboutUsPortrait from '../../assets/about-us-image-portrait.png';
import { AboutUsHeading, AboutUsText, AboutUsImage, AboutUsTextWrapper, AboutUsRow, AboutUsSubheading, AboutUsContainer } from './AboutUs.styled';

const AboutUs = () => {
  return (
    <AboutUsContainer fluid id="About Us">
      <AboutUsRow>
        <Col md={6}>
          <AboutUsImage src={AboutUsPortrait} alt="PT Hatiga Tunas Abadi" />
        </Col>
        <Col md={6}>
          <AboutUsHeading>
            PT Hatiga Tunas Abadi
          </AboutUsHeading>
          <AboutUsSubheading>
            Your Source for High-Performance Chemicals in Indonesia
          </AboutUsSubheading>
          <AboutUsTextWrapper>
            <AboutUsText>
            Fuel your operations with the power of top-tier chemicals, expertly delivered throughout Indonesia. PT Hatiga Tunas Abadi, a government-licensed and industry-recognized leader, brings you a comprehensive portfolio of solutions to meet your demanding needs.
            </AboutUsText>
            <br />
            <AboutUsText>
              Crafted with precision and sourced from leading global manufacturers, our chemicals meet the highest industry standards. We offer a diverse range from industrial solvents and polymers to food additives and water treatment solutions, ensuring you have the right tools for success.
            </AboutUsText>
            <br />
            <AboutUsText>
              With a robust nationwide distribution network, we deliver your chemicals efficiently and securely, directly to your doorstep. Trust PT Hatiga Tunas Abadi to keep your operations running smoothly, no matter your location in Indonesia.
            </AboutUsText>
            <br />
            <AboutUsText>
            Unlock the power of high-performance chemicals and take your operations to the next level. Contact PT Hatiga Tunas Abadi today and experience the difference.
            </AboutUsText>
          </AboutUsTextWrapper>
        </Col>
      </AboutUsRow>
    </AboutUsContainer>
  )
}

export default AboutUs;